"use client";

import useCart from "@/hooks/store/useCart";
import useStore from "@/hooks/store/useStore";
import { FiShoppingCart } from "react-icons/fi";

export function CartLink() {
  const totalCount = useStore(useCart, (state) => state.count) || 0;

  return (
    <div className="relative inline-flex items-center">
      <FiShoppingCart className="h-5 w-5" />
      {totalCount > 0 ? (
        <div className="absolute top-0 p-2 right-0 transform translate-x-[80%] -translate-y-4 flex items-center justify-center w-4 h-4 rounded-full bg-gray-900">
          <span className="text-xs leading-none text-white">{totalCount}</span>
        </div>
      ) : null}
    </div>
  );
}
