import { create } from "zustand";
import { persist } from "zustand/middleware";

import { objectEntries } from "@peppy/core/types/custom";
import { z } from "zod";

export const cartItemSchema = z.object({
  product: z.object({
    id: z.string(),
    slug: z.string(),
    name: z.string(),
    price: z.number(),
    discount: z.number(),
    currency: z.string(),
    image: z.string(),
    platformId: z.string(),
  }),
  store: z.object({
    id: z.string(),
    slug: z.string(),
    name: z.string(),
    image: z.string(),
  }),
});

export const cartItemWithCountSchema = cartItemSchema.and(
  z.object({
    count: z.number(),
  })
);

export type CartItem = z.infer<typeof cartItemSchema>;

export type CartItemWithCount = z.infer<typeof cartItemWithCountSchema>;

type CartItems = Record<
  string,
  {
    checkoutUrl?: string;
    items: CartItemWithCount[];
  }
>;

interface Cart {
  checkouts: CartItems;
  count: number;
  addToCart: (item: CartItem) => void;
  addCheckoutUrl: (storeId: string, checkoutUrl: string) => void;
  removeFromCart: (item: CartItem) => void;
  removeAllCart: () => void;
  setcount: (count: number) => void;
}

function calculateCount(items: CartItems) {
  return objectEntries(items).reduce(
    (acc, [_, store]) =>
      acc + store.items.reduce((acc, item) => acc + item.count, 0),
    0
  );
}

const useCart = create(
  persist<Cart>(
    (set) => ({
      checkouts: {},
      count: 0,
      addToCart: (item) =>
        set((state) => {
          const storeId = item.store.id;
          const checkoutUrl = state.checkouts[storeId]?.checkoutUrl;
          // if storeId exists, check if item exists. If yes then only increase count, else add new item
          if (state.checkouts[storeId]) {
            const items = state.checkouts[storeId].items;
            const index = items.findIndex(
              (i) => i.product.id === item.product.id
            );
            if (index !== -1) {
              items[index].count += 1;
              const newItems = {
                ...state.checkouts,
                [storeId]: {
                  checkoutUrl,
                  items,
                },
              };
              const count = calculateCount(newItems);
              return { checkouts: newItems, count };
            }
          }
          const newItems = {
            ...state.checkouts,
            [storeId]: {
              checkoutUrl,
              items: [
                ...(state.checkouts[storeId]?.items || []),
                {
                  ...item,
                  count: 1,
                },
              ],
            },
          };
          const count = calculateCount(newItems);
          return { checkouts: newItems, count };
        }),
      addCheckoutUrl: (storeId, checkoutUrl) =>
        set((state) => {
          const newItems = {
            ...state.checkouts,
            [storeId]: {
              ...state.checkouts[storeId],
              checkoutUrl,
            },
          };
          return { checkouts: newItems };
        }),
      removeFromCart: (item) =>
        set((state) => {
          const storeId = item.store.id;
          // if storeId exists, check if item exists. If yes then only decrease count, else remove item
          if (state.checkouts[storeId]) {
            const items = state.checkouts[storeId].items;
            const index = items.findIndex(
              (i) => i.product.id === item.product.id
            );
            if (index !== -1) {
              items[index].count -= 1;
              if (items[index].count === 0) {
                const newItems = {
                  ...state.checkouts,
                  [storeId]: {
                    checkoutUrl: state.checkouts[storeId]?.checkoutUrl,
                    items: items.filter(
                      (i) => i.product.id !== item.product.id
                    ),
                  },
                };
                if (newItems[storeId].items.length === 0) {
                  delete newItems[storeId];
                }
                const count = calculateCount(newItems);
                return { checkouts: newItems, count };
              }
              const newItems = {
                ...state.checkouts,
                [storeId]: {
                  checkoutUrl: state.checkouts[storeId]?.checkoutUrl,
                  items,
                },
              };
              if (newItems[storeId].items.length === 0) {
                delete newItems[storeId];
              }
              const count = calculateCount(newItems);
              return { checkouts: newItems, count };
            }
          }
          const newItems = {
            ...state.checkouts,
            [storeId]: {
              checkoutUrl: state.checkouts[storeId]?.checkoutUrl,
              items: state.checkouts[storeId]?.items.filter(
                (i) => i.product.id !== item.product.id
              ),
            },
          };
          const count = calculateCount(newItems);
          return { checkouts: newItems, count };
        }),
      removeAllCart: () => set({ checkouts: {}, count: 0 }),
      setcount: () =>
        set((state) => {
          const count = calculateCount(state.checkouts);
          return { count };
        }),
    }),
    {
      name: "cart-storage",
    }
  )
);

export default useCart;
