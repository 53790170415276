"use client";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import useStore from "@/hooks/store/useStore";
import useUser from "@/hooks/store/useUser";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Button } from "./ui/button";

export function DrawerMenu() {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const user = useStore(useUser, (state) => state.user);
  const { removeUser } = useUser();
  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger>
        <FiMenu className="w-6 h-6" />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader className="flex justify-center">
          <DrawerTitle>Discover unseen @ Peppyhop</DrawerTitle>
          {/* <DrawerDescription>This action cannot be undone.</DrawerDescription> */}
        </DrawerHeader>
        <div className="flex px-6 gap-x-6 justify-center">
          <Button
            variant="outline"
            onClick={() => {
              setOpen(false);
              router.push("/cart");
            }}
          >
            Cart
          </Button>
          {!user ? (
            <Button
              variant="outline"
              onClick={() => {
                setOpen(false);
                router.push("/auth/login");
              }}
            >
              Sign in
            </Button>
          ) : (
            <Button
              variant="outline"
              onClick={() => {
                setOpen(false);
                removeUser();
                router.push("/auth/logout");
              }}
            >
              Logout
            </Button>
          )}
        </div>
        <DrawerFooter>
          {/* <Button>Submit</Button>*/}
          <DrawerClose asChild>
            <Button variant="ghost">
              <FiX />
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
