import type { ParsedUrl } from "unpic";
import { z } from "zod";

// | { name: string; content: string }
// | { property: string; content: string };

export type ParsedImageInfo = ParsedUrl<Record<string, unknown>> | undefined;

export type ResponseObj<T> =
  | {
      status: "successful";
      data?: T;
      cursor?: string | number;
      message: string;
    }
  | {
      status: "failed";
      message: string;
      error?: Error;
    };

type ValueOf<T> = T[keyof T];
type Entries<T> = [keyof T, ValueOf<T>][];

export function objectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}

export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> &
    Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
  U[keyof U];

export type NullablePartial<T> = {
  [K in keyof T]: T[K] | null;
};

export const metaSchema = z.object({
  perPage: z.number(),
  offset: z.number(),
  total: z.number(),
  nextPage: z.number().nullable(),
  prevPage: z.number().nullable(),
  hasNextPage: z.boolean(),
  pages: z.number(),
  pageNumber: z.number(),
});

export type PaginatedMeta = z.infer<typeof metaSchema>;

type RequiredNotNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export type Ensure<T, K extends keyof T> = T & RequiredNotNull<Pick<T, K>>;
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
