"use client";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useStore from "@/hooks/store/useStore";
import useUser from "@/hooks/store/useUser";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { FiUser } from "react-icons/fi";

export function UserNav({
  account,
}: {
  account?: {
    name?: string;
    email: string;
    picture?: string;
    role?: "store_admin" | "shopper" | "guest_shopper";
  };
}) {
  const loggedInUser = useStore(useUser, (state) => state.user);
  const { addUser, removeUser } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (account) {
      addUser(account);
    }
  }, [account, addUser]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-9 w-9">
            <AvatarImage
              src={account?.picture || "/images/avatars/guy.png"}
              alt="@user"
            />
            <AvatarFallback>
              <FiUser className="w-4 h-4" />
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        {account ? (
          <>
            <DropdownMenuLabel className="font-normal">
              <div className="flex flex-col space-y-1">
                <p className="text-sm font-medium leading-none">
                  {account.name}
                </p>
                <p className="text-xs leading-none text-muted-foreground">
                  {loggedInUser?.email || account.email}
                </p>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onSelect={() => {
                removeUser();
                router.push("/auth/logout");
              }}
            >
              Sign out
              <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
            </DropdownMenuItem>
          </>
        ) : (
          <DropdownMenuItem
            onSelect={() => {
              router.push("/auth/login");
            }}
          >
            Sign in
            <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
