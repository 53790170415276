import { create } from "zustand";
import { persist } from "zustand/middleware";

import { z } from "zod";

export const userSchema = z.object({
  name: z.string().optional(),
  email: z.string().email().optional(),
  picture: z.string().optional(),
});

type UserInfo = z.infer<typeof userSchema>;

interface User {
  user?: UserInfo;
  addUser: (item: UserInfo) => void;
  removeUser: () => void;
}

const useUser = create(
  persist<User>(
    (set) => ({
      user: undefined,
      addUser: (item: UserInfo) =>
        set(() => ({
          user: item,
        })),
      removeUser: () =>
        set(() => ({
          user: undefined,
        })),
    }),
    {
      name: "user-storage",
    }
  )
);

export default useUser;
