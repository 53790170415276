"use client";

export function PriceDiv({
  price,
  className,
}: {
  price: string | number;
  className?: string;
}) {
  const formatted = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  }).format(Number(price));
  const [symbol, ...rest] = formatted.split("");
  return (
    <span className={className}>{`${symbol} ${rest.join("").trim()}`}</span>
  );
}
